import React, { useRef, useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, chakra, Box, Text, Button, Flex, useToast, Input } from '@chakra-ui/react'
import { useTable, useSortBy } from 'react-table'
import Cookies from 'universal-cookie';
import DatePicker from 'react-date-picker';
import '../../css/DatePicker.css';
import '../../css/Calendar.css';
import '../../css/App.css'

import TableHead from '../../GlobalComponents/TableHead';
import WebsiteHeader from '../../GlobalComponents/WebsiteHeader';
import HistoryBox from '../HistoryBox';
import { ErrorToast, WarnToast, CheckToast } from '../../GlobalComponents/CustomToast'; 

export default function PaketSuche(props) {


    const [curent, setCurent] = useState('');
    const [tableData, setTableData] = useState([]);
    const [Date1, setDate1] = useState(new Date());
    const [Date2, setDate2] = useState(new Date());
    const [history, setHistory] = useState([]);
    const [historyOpen, setHistoryOpen] = useState(false);
    const [historyImage, setHistoryImage] = useState('');
    const [HistoryImgDir, setHistoryImgDir] = useState('');

    const [update, setUpdate] = useState('');
    const toast = useToast();
    const toastIdRef = React.useRef();
    const searchRef = useRef(null);

    const columns = [
        { label: 'Name1', accessor: 'Name1', sortable: true, width: 80 },
        { label: 'Name2', accessor: 'Name2', sortable: true, width: 80 },
        { label: 'Name3', accessor: 'Name3', sortable: true, width: 80 },
        { label: 'Name4', accessor: 'Name4', sortable: true, width: 80 },
        { label: 'Konto', accessor: 'Konto', sortable: true, width: 80 },
        { label: 'Telefon', accessor: 'Telefon', sortable: true, width: 80 },
        { label: 'Strasse', accessor: 'Strasse', sortable: true, width: 80 },
        { label: 'PLZ', accessor: 'PLZ', sortable: true, width: 80 },
        { label: 'Ort', accessor: 'Ort', sortable: true, width: 80 },
        { label: 'Recipient', accessor: 'Recipient', sortable: true, width: 80 },
        { label: 'Lieferschein', accessor: 'Lieferschein', sortable: true, width: 60 },
        { label: 'Paketnummer', accessor: 'Paketnummer', sortable: true, width: 60 },
        { label: 'Druckdatum', accessor: 'Druckdatum', sortable: true, width: 60 },
    ]
    const handleSorting = (sortField, sortOrder) => {
        if (sortField) {
            const sorted = [...tableData].sort((a, b) => {
                if (a[sortField] === null) return 1;
                if (b[sortField] === null) return -1;
                if (a[sortField] === null && b[sortField] === null) return 0;
                return (
                    a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
                        numeric: true,
                    }) * (sortOrder === "asc" ? 1 : -1)
                );
            });
            setTableData(sorted);
        }
    };
    async function APIConnector(target, params, json = {}) {

        var url = props.server + '/API/index.php/' + target + params;
        const cookies = new Cookies();
        var UserToken = cookies.get('LoginToken');
        json.Token = UserToken;
        //console.log(json)
        try {
            const response = await fetch(url, {
                method: 'POST',
                //headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Basic VHJhdmVsZXI6VGhpczEhSXMyIlRoZTPCp1Bhc3N3b3JkNCQ=' },
                body: JSON.stringify(json)
            });
            const results = await response.json();
            //console.log(results)
            if (results === 'invalid') {
                props.searchCall('logOut')
            } else if (results.AuthKey === 'noRight') {
                toast({ render: () => ( <WarnToast title='Berechtigungen Fehlerhaft' descr='Sie besitzen keine Berechtigung für diese Aktion!'/>), status: 'error', duration: 7000, isClosable: true, position: 'top', })
                props.searchCall('newRights', results.Rights)
            } else return results
        } catch (error) { console.log(error) }
    }
    async function _fetchPakete(text) {
        var start = Date1.getFullYear() + '-' + (Date1.getMonth() + 1) + '-' + Date1.getDate()
        var end = Date2.getFullYear() + '-' + (Date2.getMonth() + 1) + '-' + Date2.getDate()

        var search = text;
        var params = '?start=' + start + '&end=' + end + '&search=' + search

        var data = await APIConnector('Web_SearchPaket', params, {})
        if (Array.isArray(data)) {
            setTableData(data);
        } else toast({ render: () => ( <ErrorToast title='Abruf fehlgeschlagen!' description="Die Serverantwort war fehlerhaft!" />), status: 'error', duration: 5000, isClosable: true, position: 'top', })
    }
    async function _fetchHistory(PaketNr) {

        var params = '?PaketNr=' + PaketNr;

        var data = await APIConnector('GET_PaketHistory', params, {})
        if (Array.isArray(data)) {
            setHistory(data);
        } else toast({ render: () => ( <ErrorToast title='Abruf fehlgeschlagen!' descr="Die Serverantwort war fehlerhaft!"/>), status: 'error', duration: 5000, isClosable: true, position: 'top', })
    }
    const FindPaket = (text) => {

        if (text.length >= 3) {
            _fetchPakete(text)
        } else {
            toast({ render: () => ( <WarnToast title='Eingabe zu kurz!' descr='Bitte geben Sie mindestens 3 Zeichen an'/>), status: 'warning', duration: 2300, isClosable: true, position: 'top', })
        }
    }
    async function selectHistory(PaketNr, Image, Manifest, LieferID) {

        setCurent(PaketNr)
        await _fetchHistory(PaketNr)

        if(true) { 
            setHistoryImgDir('tour_images/' //+ LieferID + '/' 

            ); 
        } else {
            setHistoryImgDir('tmp/' ); 
        }

        setHistoryImage(Image)
        setHistoryOpen(true)
    }
    function HistoryCall() {
        setHistoryOpen(false)
    }
    return (
        <Flex flexDirection='column'>
            <WebsiteHeader Text='TourPortal / Paket Suche' onClick={() => props.searchCall('back')} server={props.server} User={props.ActiveUser} />

            <Flex height='93vh' backgroundColor='#ccc' p={1} flexDirection='column'>

            <Box display='flex' bg='#fff' alignItems='center' justifyContent='flex-start' flexWrap='wrap'
                mb={1} borderWidth={1} borderColor='#000' borderRadius={3} >

                <Flex m={2} width='750px' height='40px' border='0px solid black' alignItems='center' justifyContent='space-between' borderRadius={6} pl={1} bg='#1DA1F2'>
                        <Text  color='#fff' fontWeight='semibold' fontSize={17} >von:</Text>
                        <DatePicker onChange={setDate1} value={Date1} format='dd.MM.y' border='0px solid black'/>
                        <Text  color='#fff' fontWeight='semibold' fontSize={17} >bis:</Text>
                        <DatePicker onChange={setDate2} value={Date2} format='dd.MM.y' border='0px solid black'/>
                        <Input w='35%' h='35px' ref={searchRef} border='0px solid #1DA1F2' borderRadius={0} fontSize={17} bg='#fff' height='36px'
                             onKeyPress={e => { if (e.key === 'Enter') { FindPaket(searchRef.current.value) } }} placeholder='Paketnummer...'
                        />
                        <Button onClick={() => FindPaket(searchRef.current.value)} colorScheme='twitter' borderRadius='0px 5px 5px 0px'>Suche</Button>
                </Flex>  

            </Box>
            <Box display='flex' height='100%' bg='#fff' alignItems='flex-start' justifyContent='center'
                    borderWidth={1} borderColor='#000' borderRadius={3} padding={2} overflow='auto'>
                <Table size="small" variant='striped' colorScheme='blackAlpha'
                >
                    <TableHead {...{ columns, handleSorting }} />
                    <Tbody>
                        {tableData.map((row, index) => {
                            return (

                                <Tr key={row.ID} onClick={() => null}
                                    bg={'#fff'}
                                >
                                    {columns.map(({ accessor }) => {
                                        const tData = row[accessor];
                                        return (<React.Fragment key={accessor}>
                                            <Td textAlign="center" borderWidth='1.5px' borderColor='#e6e6e6' >

                                                {accessor !== 'Paketnummer' ? (
                                                    <Text noOfLines={1}>{tData}</Text>
                                                ) : (
                                                    <Button borderWidth="1px" borderColor="black" borderRadius={3} bgColor="#fff" h='6' w='100%' zIndex={-0}
                                                        onClick={() => selectHistory(row.Paketnummer, row.Image, row.Manifest, row.LieferID)}>{tData}</Button>
                                                )}
                                            </Td>
                                            <Td className="columnResizer" />
                                        </React.Fragment>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </Box>

            </Flex>

            <HistoryBox isOpen={historyOpen} server={props.server} paketCall={() => HistoryCall()} ImageDir={HistoryImgDir} Image={historyImage} History={history} curentPaket={curent} />
        </Flex >
    );
}
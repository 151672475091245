import React, { useState, useEffect, useRef } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, chakra, Box, Text, Button, Icon, Flex, useToast, Center, Input, Select, InputGroup, InputRightElement, VStack, Tooltip, Spacer, Alert, Square } from '@chakra-ui/react'

import { useTable, useSortBy } from 'react-table'
import { FiSettings, FiArrowRight, FiArrowLeft, FiTrash, FiTrash2 } from 'react-icons/fi'
import { FaUser, FaEyeSlash, FaEye, FaInfo } from 'react-icons/fa'
import { FileUploader } from 'react-drag-drop-files';

import { ErrorToast, WarnToast, CheckToast } from '../GlobalComponents/CustomToast';
import WebsiteHeader from '../GlobalComponents/WebsiteHeader';
import TableHead from '../GlobalComponents/TableHead';
import ChacraAlert from '../GlobalComponents/ChakraAlert';

export default function EditUser(props) {

    //const [tableData, setTableData] = useState([]);
    const [RolesData, setRolesData] = useState([]);
    const [TeamsData, setTeamsData] = useState([]);
    const [UserTeams, setUserTeams] = useState([]);
    const [PassCheck, setPassCheck] = useState([]);
    const [editPassword, setEditPassword] = useState(false);
    const [vissPassword, setVissPassword] = useState(false);
    const [ImageP, setImage] = useState({ preview: "", raw: "" });
    const [chacraAlert, setChacraAlert] = useState({ isOpen: false, header: 'Header', text: 'Description', closeBtn: 'cancel', actBtn: 'action', onClose: null, onAction: null });
    const [change, setChange] = useState(false);
    const [dragActive, setDragActive] = React.useState(false);

    const NameRef = useRef(null);
    const NickRef = useRef(null);
    const PasswordRef = useRef(null);
    const RolesRef = useRef(null);

    const toast = useToast();
    const toastIdRef = React.useRef();

    const fileTypes = ["JPG", "PNG", "GIF"];

    useEffect(() => {
        GET_RolesAndTeams(props.currentUser.User_ID);
        LoadInitialPicture();

        console.log(props.currentUser)
    }, []);

    async function GET_RolesAndTeams(Id) {

        var value = { target: 'Web_RolesAndTeams', params: '?UserID=' + Id, body: {} }
        var data = await props.EditUserCall('request', value)

        if (typeof data == 'object') {
            setRolesData(data.Roles)
            setTeamsData(data.TeamsOut)
            setUserTeams(data.TeamsIn)
        } else {
            //alert('Abfrage Fehlgeschlagen!');
        }
    }
    async function LoadInitialPicture() {

        if (props.currentUser.User_Img && props.currentUser.User_Img !== '') {

            setImage({ preview: props.server + '/API/icons/' + props.currentUser.User_Img, raw: '' })

        }
    }
    async function SendUserChange(Changes) {

        var body = { UserChanges: Changes };

        var value = { target: 'Web_SaveUserChange', params: '', body: body }
        var data = await props.EditUserCall('request', value, true)

        if (data === 'success') {
            toast({ render: () => ( <CheckToast title='UserDaten werden gesendet' descr='Daten wurden gespeichert'/>), 
                status: 'info', duration: 7000, isClosable: true, position: 'top', })
            CloseEditView();
        } else if (data === 'exists'){
            toast({ render: () => ( <WarnToast 
                title='Benutzerdaten Konflikt' descr="Der Benutzername oder das Kürzel existieren bereits für einen anderen Benutzer!"/> ), 
                duration: 7000, isClosable: true, position: 'top' })
        } else {
            toast({  render: () => ( <ErrorToast 
                title='Benutzerdaten fehlerhaft' descr="Die angegebenen Benutzerdaten konnten nicht gespeichert werden!"/>), 
                status: 'error', duration: 7000, isClosable: true, position: 'top', })
        }
    }
    function CloseEditView() {
        var Alert = { ...chacraAlert }; Alert.isOpen = false;
        setChacraAlert(Alert);
        setChange(false);
        props.EditUserCall('back');
    }
    async function CheckSaveData(){

        if (NameRef.current.value.length < 4) { 
            toast({ render: () => ( <WarnToast title='Benutzername unzulässig' descr="Benutzername muss mindestenz 4 Zeichen lang sein!" />),
            status: 'error', duration: 7000, isClosable: true, position: 'top', })
            return;
        } 

        if (NickRef.current.value.length > 0 && NickRef.current.value.length < 4) {
            toast({  render: () => ( <WarnToast title='Kürzel unzulässig' descr="Kürzel muss mindestenz 4 Zeichen lang sein!"/>), 
            status: 'error', duration: 7000, isClosable: true, position: 'top', })
            return;
         }

        //if (PasswordRef.current.value.length > 0 && PasswordRef.current.value.length < 4) { }

        collectSavedData();
    }
    async function collectSavedData() {

        var Username = NameRef.current.value;
        var Nickname = NickRef.current.value;
        var Password = PasswordRef.current.value;
        var Team_IDs = []
        for (let I = 0; I < UserTeams.length; I++) {
            Team_IDs.push(UserTeams[I].Team_ID)
        }

        var UpdateObj = {}

        UpdateObj.User_ID = props.currentUser.User_ID
        if (Username !== '' && Username !== null ) UpdateObj.User_Name = Username
        if (Nickname !== '' && Nickname !== null ) UpdateObj.User_Nick = Nickname
        if (Password !== '' && Password !== 'Passwort') UpdateObj.User_Password = Password
        UpdateObj.User_Role = RolesRef.current.value
        UpdateObj.User_Teams = Team_IDs

        if (ImageP.raw !== '') {
            var Base64 = await convertToBase64(ImageP.raw)
            UpdateObj.User_IcName = ImageP.raw.name;
            UpdateObj.User_Icon = Base64.slice(Base64.lastIndexOf(';base64,') + 8)
        } else if (ImageP.preview !== '') {
            UpdateObj.User_IcName = props.currentUser.User_Img;
        } else {
            UpdateObj.User_IcName = '';
        }
        ////console.log(UpdateObj)
        SendUserChange(UpdateObj)

    }
    function checkPassword(value) {

        var PassTest = {}

        if (value.length > 11) PassTest.length = true//12 Stellen lang
        if (value.toUpperCase() !== value && value.toLowerCase() !== value) PassTest.smallBig = true//Groß - und Kleinbuchstaben
        if (/\d/.test(value)) PassTest.numbers = true//Zahlen
        if (/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value)) PassTest.special = true//Sonderzeichen

        setPassCheck(PassTest)
        setChange(true);
    }
    function asignTeams(Side, TeamID) {

        var TeamsLeft = [...UserTeams];
        var TeamsRight = [...TeamsData];

        if (Side === 'in') {
            var Index = TeamsLeft.findIndex((elem) => elem.Team_ID === TeamID)
            if (Index !== -1) {
                TeamsRight.push(TeamsLeft[Index]);
                TeamsLeft.splice(Index, 1);
            }
        } else {
            var Ind = TeamsRight.findIndex((elem) => elem.Team_ID === TeamID)
            if (Ind !== -1) {
                TeamsLeft.push(TeamsRight[Ind]);
                TeamsRight.splice(Ind, 1);
            }
        }
        setTeamsData(TeamsRight);
        setUserTeams(TeamsLeft);
        setChange(true);

    }
    const imageSelected = async (e) => {
        if (e.target.files.length) {

            var newImage = e.target.files[0];
            await ImgReciever(newImage)
        }
    };
    const ImgReciever = async (newImage) => {
        if (newImage.size / 1024 > 4096) {
            alert("Dieses Bild ist leider zu groß!");
            return
        }
        setChange(true);
        setImage({
            preview: URL.createObjectURL(newImage),
            raw: newImage
        });
    }

    function convertToBase64(selectedFile) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(selectedFile)
            reader.onload = () => resolve(reader.result)
            reader.onerror = reject
        })
    }
    // triggers when file is dragged over
    const handleDrag = function (e) {

        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {

            var newImage = e.dataTransfer.files[0];
            ImgReciever(newImage)
        }
    };

    function cancelChangeTest() {
        if (change) {
            ExitAlert();
        } else {
            props.EditUserCall('back')
        }
    }
    async function SendUserDelete(UserID) {
        var value = { target: 'Web_UnsetUser', params: '?User=' + UserID, body: {} }
        var data = await props.EditUserCall('request', value, true)
        //var data = 'success'
        if (data === 'success') {
            toast({  render: () => ( <CheckToast title='Benutzer gelöscht!' descr='Der Benutzer wurde erfolgreich gelöscht'/>), 
                status: 'info', duration: 7000, isClosable: true, position: 'top', })
            props.EditUserCall('back');
        } else {
            Alert('Verbundung Fehlgeschlagen!')
        }
    }
    function confirmDeleteHandler() {
        var Alert = { ...chacraAlert };
        Alert.isOpen = true;
        Alert.header = 'Benutzer "' + props.currentUser.User_Name + '" löschen?';
        Alert.text = 'Möchten Sie diesen Benutzer wirklich löschen?';
        Alert.closeBtn = 'Abbrechen'
        Alert.actBtn = 'Löschen'
        Alert.onClose = () => closeAlert();
        Alert.onAction = () => SendUserDelete(props.currentUser.User_ID);
        setChacraAlert(Alert)
    }

    function ExitAlert() {
        var Alert = { ...chacraAlert };
        Alert.isOpen = true;
        Alert.header = 'Ungespeicherte Änderungen!';
        Alert.text = 'Möchten Sie wirklich alle Änderungen verwerfen?';
        Alert.closeBtn = 'Zurück'
        Alert.actBtn = 'Änderungen verwerfen'
        Alert.onClose = () => closeAlert();
        Alert.onAction = () => props.EditUserCall('back');
        setChacraAlert(Alert)
    }
    function closeAlert() {
        var Alert = { ...chacraAlert }; Alert.isOpen = false; setChacraAlert(Alert)
    }
    /*
    const NameWarnComp =() => {
        return(
            <Flex bgColor='red' width={300} borderRadius={5} alignItems='center' justifyContent='center' mt={-2} mr={5} zIndex={10} alignSelf='flex-end'>
                <Icon as={FaInfo} color='#fff' boxSize='15px' />
            <Text fontSize='17' color='#fff' >Bitte mindestens 4 Zeichen angeben</Text>
            </Flex>
        )}
    */
    const createSelect = () => { //waits for RolesData to load before setting Default Value
        if (RolesData.length === 0) {
            return <Text>Rollen werden geladen...</Text>
        } else {
            return (
                <Select w='100%' defaultValue={props.currentUser.Role_ID} ref={RolesRef} onSelect={() => setChange(true)} onChange={() => setChange(true)} >
                    {
                        RolesData.map((elem) => {
                            return (
                                <option key={elem.Role_ID} value={elem.Role_ID}>{elem.Role_Name} | {elem.Role_Dscr}</option>
                            )
                        })}
                </Select>
            )
        }
    }

    const TeamsComponent = (Team, Side) => {
        return (
            <Flex key={Team.Team_ID}  
            flexDirection='row' justifyContent='flex-start' alignItems='center' w='100%' minHeight='30px' bgColor='#fff' borderRadius={5}>

                <Button colorScheme='teal' w='15px' h='100%' borderRadius={0} borderLeftRadius={5}
                 onClick={() => asignTeams(Side, Team.Team_ID)}>
                    <Icon as={Side === 'in' ? (FiArrowRight) : (FiArrowLeft)} color='#fff' boxSize='25px' />
                </Button>

                <Tooltip label={Team.Team_Dscr} hasArrow placement='top'>
                    <Text ml={10} fontWeight='bold' >{Team.Team_Name}</Text>
                </Tooltip>
                {/*<Text m={0} w='50%' fontSize={13} >{Team.Team_Dscr}</Text>*/}
            </Flex>
        )
    }

    return (
        <Flex flexDirection='column'>
            <ChacraAlert Alert={chacraAlert} />
            <WebsiteHeader Text='TourPortal / Nutzer Bearbeiten' onClick={() => props.EditUserCall('back')} server={props.server} User={props.ActiveUser}
            />

            <Flex borderWidth={1} borderRadius={10} m={1} borderColor='#000' bgColor='#eee' alignItems='flex-start' h='92vh' justifyContent='center' overflow='auto' overFlowX='auto' p={5} minHeight='685px'minWidth='780px'>
                <Flex h='100%' w='40%' minHeight='640px' minWidth='730px' bg='white' flexDirection='column' p={3} overflow='auto' justifyContent='space-between' borderRadius={7}>

                    <Flex h='50%' flexDirection='row' overflow=''>

                        <Flex w='75%' flexDirection='column' justifyContent='space-evenly'>

                            <Flex flexDirection='column'>
                            <Text fontSize='27' fontWeight='bold'>Benutzername:</Text>
                            <Input w='100%'  minHeight={39} borderWidth={2.5} defaultValue={props.currentUser.User_Name} ref={NameRef} onChange={() => setChange(true)} />
                            </Flex>
                            <Flex flexDirection='column'>
                            <Text fontSize='27' fontWeight='bold'>Kürzel:</Text>
                            <Input w='100%' minHeight={39} borderWidth={2.5} defaultValue={props.currentUser.User_Nick} ref={NickRef} onChange={() => setChange(true)} />
                            </Flex>
                            <Flex flexDirection='column'>
                            <Text fontSize='27' fontWeight='bold'>Passwort:</Text>
                            <InputGroup >
                                <Input defaultValue='Passwort' onChange={event => checkPassword(event.target.value)}
                                    type={vissPassword ? 'text' : 'password'} borderWidth={2.5} isDisabled={!editPassword} ref={PasswordRef} />
                                <InputRightElement w='30%'>

                                    <Button onClick={() => setVissPassword(!vissPassword)} w='35px' colorScheme='teal' h='2rem' >
                                        <Icon as={vissPassword ? (FaEyeSlash) : (FaEye)} color='#fff' boxSize='22px' />
                                    </Button>

                                    <Button onClick={() => setEditPassword(!editPassword)} w='100px' colorScheme='teal' h='2rem' m='3px'>
                                        {editPassword ? 'Schließen' : 'Bearbeiten'}
                                    </Button>

                                </InputRightElement>
                            </InputGroup>

                            <Flex flexDirection='row' overflow='' padding='3px' hidden={!editPassword}
                                borderWidth={1} borderTopWidth={0} borderBottomRadius={5} borderColor='#ddd' w='100%' justifyContent='space-between'
                            >
                                <Text fontSize='15' m={2} fontStyle='italic'>{PassCheck.length ? ('√ ') : (null)}12 Stellen lang</Text>
                                <Text fontSize='15' m={2} fontStyle='italic'>{PassCheck.smallBig ? ('√ ') : (null)}Groß - und Kleinbuchstaben</Text>
                                <Text fontSize='15' m={2} fontStyle='italic'>{PassCheck.numbers ? ('√ ') : (null)}Zahlen</Text>
                                <Text fontSize='15' m={2} fontStyle='italic'>{PassCheck.special ? ('√ ') : (null)}Sonderzeichen</Text>
                            </Flex>
                            </Flex>
                        </Flex>
                        <Flex w='25%' padding={2} flexDirection='column'>

                            <form onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                                <label htmlFor="upload-button" style={{ width: '100%' }}>

                                    {ImageP.preview ? (
                                        <Box style={dragActive ? { borderColor: '#bbb', borderRadius: 10, borderWidth: 2} : { borderColor: 'black', borderRadius: 10, borderWidth: 2 }}
                                            onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>

                                            <img src={ImageP.preview} alt="Profilbild" style={{ borderRadius: 6, objectFit: 'cover', width: '100%', height: 160 }} />
                                        </Box>
                                    ) : (
                                        <Center style={dragActive ? { borderColor: '#bbb', borderRadius: 10, borderWidth: 2 } : { borderColor: 'black', borderRadius: 10, borderWidth: 2 }}
                                            width='100%' height={160} flexDirection={'column'} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
                                            <Text>Bitte Foto</Text>
                                            <Text>auswählen</Text>
                                        </Center>
                                    )}

                                </label>
                                <input type="file" id="upload-button" style={{ display: 'none' }} height='50%' onChange={imageSelected}
                                />
                            </form>

                            <Button height='40px' width='40px' bgColor='#e14400' borderRadius={10} mt={-5} mr={-1} alignSelf='flex-end'
                                onClick={() => setImage({ preview: "", raw: "" })}>
                                <Icon as={FiTrash2} color='#fff' boxSize='25px' />
                            </Button>
                        </Flex>

                    </Flex>

                    <Flex h='15%' flexDirection='column' overflow='hidden'>
                        <Text fontSize='27' fontWeight='bold'>Role:</Text>
                        {createSelect()}
                    </Flex>
                    <Flex h='38%' flexDirection='column' overflow='hidden'>
                        <Text fontSize='27' fontWeight='bold'>Teams:</Text>
                        <Flex borderColor='#000' borderWidth={1} borderRadius={5} minHeight={100} overflowY="auto" bgColor='#eee' >

                        <VStack borderColor='#000' borderRightWidth={1} w='50%' flexDirection='column' overflowY="auto" padding={1} >
                                {
                                    UserTeams.map((elem) => {
                                        return TeamsComponent(elem, 'in')
                                    })
                                }
                            </VStack>
                            <VStack w='50%' flexDirection='column' overflowY="auto" padding={1}>

                                {
                                    TeamsData.map((elem) => {
                                        return TeamsComponent(elem, 'out')
                                    })
                                }
                            </VStack >

                        </Flex>
                    </Flex>
                    <Flex h='7%' flexDirection='row' justifyContent='space-between' marginTop={2}>

                        <Button colorScheme='red' mr={3} onClick={() => confirmDeleteHandler()}
                        >Löschen</Button>
                        <Spacer />

                        <Button variant='outline' colorScheme='teal' m='5px' onClick={() => cancelChangeTest()}>Abbrechen</Button>
                        <Button colorScheme='teal' m='5px' onClick={() => CheckSaveData()}>Speichern</Button>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}